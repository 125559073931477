import React, { useState, useRef } from 'react'
import BamContext from './BamContext'

const BamContextProvider = ({ children }) => {
  const [system, setSystem] = useState(() => {
    const storedSystem = localStorage.getItem('system')
    return storedSystem ? String(storedSystem) : ''
  })
  const order = 'Customer Order'
  const [table, setTable] = useState(false)
  const [active, setActive] = useState(false)
  const [show, setShow] = useState(false)
  const [dateSelect, setDateSelect] = useState(false)
  const [propertyDetailsRes, setPropertyDetailsRes] = useState([])
  const dropdown = useRef()
  const to = useRef()
  const systemNotActive = useRef()
  const [buttonActive, setButtonActive] = useState(() => {
    const storedButton = localStorage.getItem('button')
    if (storedButton) {
      return String(storedButton)
    } else {
      return 'button1'
    }
  })
  const [data, setData] = useState(() => {
    const systemData = sessionStorage.getItem('systemData')
    if (systemData) {
      return JSON.parse(systemData)
    } else {
      return null
    }
  })
  const [loader, setLoader] = useState(false)
  const [flows, setFlows] = useState(0)
  const [properties, setProperties] = useState(0)
  const [FlowList, setFlowList] = useState([])
  const [customer_details, setCustomer_details] = useState('')
  const [searchItem, setSearchItem] = useState('')
  const [tableData, setTableData] = useState(null)
  const [propertyData, setPropertyData] = useState(null)
  const [optSysName, setOptSysName] = useState(new Set())
  const [optSysNameAlone, setOptSysNameAlone] = useState(new Set())
  const [selectedSystemId, setselectedSystemId] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [propertyField, setPropertyField] = useState(() => {
    const storedproperties = JSON.parse(sessionStorage.getItem('property'))
    if (storedproperties) {
      console.log(storedproperties)
      return storedproperties
    } else {
      return null
    }
  })
  const [flowId, setFlowID] = useState(() => {
    const storedId = localStorage.getItem('flowId')
    if (storedId) {
      return JSON.parse(storedId)
    } else {
      return 0
    }
  })
  const [systemId, setSystemID] = useState(() => {
    const storedId = localStorage.getItem('systemId')
    if (storedId) {
      return JSON.parse(storedId)
    } else {
      return 0
    }
  })
  const [flowName, setFlowName] = useState(() => {
    const flowName = localStorage.getItem('flowname')
    if (flowName) {
      return String(flowName)
    } else {
      return ''
    }
  })
  const [inputValue, setInputValue] = useState([])
  const [selectedSystemOrFlow, setSelectedSystemOrFlow] = useState(() => {
    const systemOrFlow = localStorage.getItem('systemOrFlow')
    if (systemOrFlow) {
      return String(systemOrFlow)
    } else {
      return ''
    }
  })
  const [loaderForTable, setLoaderForTable] = useState()
  const [showStatus, setShowStatus] = useState(false)
  const [showVariableDropdown, setShowVariableDropdown] = useState(false)
  const [pageNumberCount, setPageNumberCount] = useState(1)
  const [fromBamTable, setFromBamTable] = useState(false)
  const [tabSystemId, setTabSystemId] = useState(0)
  const [isTabClicked, setIsTabClicked] = useState(false)
  const [integrationProp, setIntegrationProp] = useState([])
  const [tableBtnActive, setTableBtnActive] = useState(0)
  const [array, setArray] = useState([])
  const [systemName, setSystemName] = useState([])
  const [disableTab, setDisableTab] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [propertyName, setPropertyName] = useState('')
  const [logAnalyticsPropName, setLogAnalyticsPropName] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [selectedIntName, setSelectedIntName] = useState([])
  const [addTableList, setAddTableList] = useState([])
  const [selectedSystem, setSelectedSystem] = useState([])
  const [flowSubmitList, setflowSubmitList] = useState([])
  const [selectedNewFlowName, setSelectedNewFlowName] = useState('')
  const [selectedIntegrationList, setSelectedIntegrationList] = useState([])
  const [integrationSystemId, setIntegrationSystemId] = useState()
  const [selectedIntegrationName, setSelectedIntegrationName] = useState('')
  const [logicApp, setLogicApp] = useState('')
  const [logicAppType, setLogicAppType] = useState('')
  const [selectedLogicApp, setSelectedLogicApp] = useState('')
  const [postIntegrationList, setPostIntegrationList] = useState([])
  const [intFlowId, setIntFlowId] = useState('')
  const [selectedFlow, setSelectedFlow] = useState('')
  const [addedList, setAddedlist] = useState(false)
  const [systemNameFlow, setSystemNameFlow] = useState('')
  const [integrationList, setIntegrationList] = useState()
  const [logAnalyticsId, setLogAnalyticsId] = useState()
  const [addProperty, setAddProperty] = useState([])
  const [options, setOptions] = useState([])

  return (
    <BamContext.Provider
      value={{
        system,
        setSystem,
        order,
        dropdown,
        table,
        setTable,
        active,
        setActive,
        show,
        setShow,
        dateSelect,
        setDateSelect,
        to,
        systemNotActive,
        buttonActive,
        setButtonActive,
        data,
        setData,
        loader,
        setLoader,
        flows,
        setFlows,
        properties,
        setProperties,
        FlowList,
        setFlowList,
        customer_details,
        setCustomer_details,
        searchItem,
        setSearchItem,
        tableData,
        setTableData,
        propertyData,
        setPropertyData,
        propertyField,
        setPropertyField,
        flowId,
        setFlowID,
        systemId,
        setSystemID,
        flowName,
        setFlowName,
        inputValue,
        setInputValue,
        selectedSystemOrFlow,
        setSelectedSystemOrFlow,
        loaderForTable,
        setLoaderForTable,
        showStatus,
        setShowStatus,
        showVariableDropdown,
        setShowVariableDropdown,
        pageNumberCount,
        setPageNumberCount,
        fromBamTable,
        setFromBamTable,
        tabSystemId,
        setTabSystemId,
        isTabClicked,
        setIsTabClicked,
        integrationProp,
        setIntegrationProp,
        tableBtnActive,
        setTableBtnActive,
        array,
        setArray,
        propertyDetailsRes,
        setPropertyDetailsRes,
        optSysName,
        setOptSysName,
        optSysNameAlone,
        setOptSysNameAlone,
        selectedSystemId,
        setselectedSystemId,
        systemName,
        setSystemName,
        disableTab,
        setDisableTab,
        isModal,
        setIsModal,
        showPopup,
        setShowPopup,
        propertyName,
        setPropertyName,
        logAnalyticsPropName,
        setLogAnalyticsPropName,
        selectedValue,
        setSelectedValue,
        selectedIntName,
        setSelectedIntName,
        setAddTableList,
        addTableList,
        selectedSystem,
        setSelectedSystem,
        flowSubmitList,
        setflowSubmitList,
        selectedNewFlowName,
        setSelectedNewFlowName,
        selectedIntegrationList,
        setSelectedIntegrationList,
        integrationSystemId,
        setIntegrationSystemId,
        selectedIntegrationName,
        setSelectedIntegrationName,
        logicApp,
        setLogicApp,
        logicAppType,
        setLogicAppType,
        selectedLogicApp,
        setSelectedLogicApp,
        postIntegrationList,
        setPostIntegrationList,
        intFlowId,
        setIntFlowId,
        selectedFlow,
        setSelectedFlow,
        addedList,
        setAddedlist,
        systemNameFlow,
        setSystemNameFlow,
        integrationList,
        setIntegrationList,
        logAnalyticsId,
        setLogAnalyticsId,
        addProperty,
        setAddProperty,
        options,
        setOptions,
      }}
    >
      {children}
    </BamContext.Provider>
  )
}

export default BamContextProvider
